<script setup>
import { onMounted } from 'vue';
import Utils from '@/main/resources/source/vue/composables/utils.js';
import TextComponent from '@/main/resources/source/vue/components/TextComponent.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const id = Utils.id(props.data.id);
const {
  label, caption, separatorColor, view,
} = props.data;

onMounted(() => {
  document.getElementById(id).style.borderColor = separatorColor;
});
</script>

<template>
  <div
    :id="id"
    class="accordion-data-row"
    :class="`accordion-data-row--view__${view.toLowerCase()}`"
  >
    <div class="accordion-data-row--label">
      <TextComponent :data="label" />
    </div>
    <div class="accordion-data-row--caption">
      <TextComponent :data="caption" />
    </div>
  </div>
</template>

<script setup>
import Utils from '@/main/resources/source/vue/composables/utils.js';
import Images from '@/main/resources/source/vue/composables/images.js';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const id = Utils.id(props.data.id);
const fieldLabel = props.data.label;
const { placeholder, resendCodeLabel } = props.data;
const size = `${80 + props.data.size * 13}px`;
const wrapperSize = `${128 + props.data.size * 13}px`;

const arrowImage = new Images('arrow_right_small').get();
</script>

<template>
  <div class="field">
    <label
      :for="id"
      class="field--hint field--hint__pin"
    >{{ fieldLabel }}</label>
    <div
      class="pin-wrapper"
      :style="{ width: wrapperSize }"
    >
      <input
        :id="id"
        :style="{ width: size }"
        class="pin"
        type="number"
        :name="id"
        inputmode="numeric"
        autocomplete="one-time-code"
        :placeholder="placeholder"
      >
      <button
        :id="id + '-submit'"
        class="button button--arrow"
        type="submit"
        v-html="arrowImage"
      />
    </div>
    <div
      v-if="resendCodeLabel"
      class="resend-pin typography--body_small typography--center"
      v-html="resendCodeLabel"
    />
    <div class="clear" />
  </div>
</template>

import Utils from '@/main/resources/source/vue/composables/utils.js';
import DomContentManipulator from '@/main/resources/source/vue/composables/domContentManipulator.js';
import ElementBlocker from '@/main/resources/source/vue/composables/elementBlocker.js';

/**
 * Object that is responsible for network calls functionality.
 */
const Fetcher = {
  /**
   * Function that is responsible for making async requests using XMLHttpRequest to the backend.
   * In case if XMLHttpRequest is unavailable network call will fail with a generic error.
   *
   * @param requestObject Object for request configuration.
   * @param blockedElementPointer Pointer on HTML element that should be unlocked after
   *   XMLHttpRequest is coming to DONE state.
   * @param config - reactive config with states and shit
   *
   * @param requestObject.url Full url of the request.
   * @param requestObject.method Request method. If no method is specified - GET method will be
   *   used as a default.
   * @param requestObject.body Request body object.
   * @param requestObject.headers Request headers object. If body is an object and method is "POST"
   *   then Content-Type header will be set to application/x-www-form-urlencoded.
   * @param requestObject.timeout Request timeout. Default is 10000.
   * @param requestObject.success Function that will be triggered in case of successful response.
   *   Default is {@link DomContentManipulator.processResponse}
   * @param requestObject.failure Function that will be triggered in case of error response.
   *   Default is {@link DomContentManipulator.processResponse}
   * @param requestObject.after Function that will be triggered after XMLHttpRequest is coming to
   *   DONE state.
   */
  request(requestObject, config, blockedElementPointer = null) {
    if (typeof XMLHttpRequest === 'undefined') {
      DomContentManipulator.displayLocalErrorNotification(config);
      return;
    }
    if (!requestObject || !requestObject.url) {
      return;
    }

    const request = new XMLHttpRequest();
    const { url } = requestObject;
    const method = requestObject.method || 'GET';
    const success = typeof requestObject.success === 'function' ? requestObject.success : DomContentManipulator.processResponse;
    const failure = typeof requestObject.failure === 'function' ? requestObject.failure : DomContentManipulator.processResponse;
    const after = typeof requestObject.after === 'function' ? requestObject.after : undefined;
    const timeout = typeof requestObject.timeout === 'number' ? requestObject.timeout : 30000;
    const { headers } = requestObject;
    let body = requestObject.body || {};

    request.open(method, url);

    if (Utils.hasOwnPropertyPrototypePointer(request, 'timeout')) {
      // There is a way to work around the timeout functionality, however
      // it's slightly fishy and can be implemented later. As for now, we are handling the
      // source of an actual error report.
      request.timeout = timeout;
    }
    if (headers) {
      Object.keys(headers)
        .filter((headerProperty) => Utils.hasOwnPropertyPrototypePointer(headers, headerProperty))
        .forEach((headerProperty) => request.setRequestHeader(
          headerProperty,
          headers[headerProperty],
        ));
    }

    if (typeof body === 'object' && method.toUpperCase() === 'POST') {
      // Note that headers are overridden in this case
      request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      // Add CSRF header for post requests
      request.setRequestHeader('X-SIGNIN-TELENORID-COM-CSRF-PROTECTION', 1);
      // Track user interactions timestamps for any POST request
      body.pageWasRenderedTimestamp = encodeURIComponent(config.pageWasRenderedTimestamp);
      body.currentInteractionStartTimestamp = encodeURIComponent(
        config.currentInteractionStartTimestamp,
      );
      body.newStateHasArrivedTimestamp = encodeURIComponent(config.newStateHasArrivedTimestamp);
      body.currentInteractionEndTimestamp = encodeURIComponent(Utils.getCurrentTimestamp());
      body.clientTimeZoneOffset = encodeURIComponent(Utils.getCurrentTimezoneOffset());
      body.clientTimeZone = encodeURIComponent(Utils.getCurrentTimeZone());
      body.pHash = window.tidpHash;
      body.hHash = window.tidhHash;
      body.host = window.tidHost;

      const query = [];
      if (body) {
        Object.keys(body)
          .filter((bodyProperty) => Utils.hasOwnPropertyPrototypePointer(body, bodyProperty))
          .forEach((bodyProperty) => query.push(`${encodeURIComponent(bodyProperty)}=${encodeURIComponent(body[bodyProperty])}`));
      }

      body = query.join('&');
    }

    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        // When request is done - set the new starting timestamp. We don't care about the
        // success status of request
        config.currentInteractionStartTimestamp = Utils.getCurrentTimestamp();
        if (request.status >= 200 && request.status < 400) {
          success({ config, request });
        } else if (request.status === 401) {
          Fetcher.request({
            url: `${config.apiUrl}state`,
            after() {
              DomContentManipulator.processResponse({ config, request });
            },
          }, config);
        } else {
          failure({ config, request });
        }

        if (after) {
          after();
        }

        if (ElementBlocker.existsAndBlocked(blockedElementPointer)) {
          ElementBlocker.unblock({ config, elementPointer: blockedElementPointer });
        }
      }
    };
    request.ontimeout = () => {
      // If request times out - don't set the new starting timestamp.
      failure({ config, request });
    };
    request.send(body);
  },
};

export default Fetcher;

<script setup>
import { ref } from 'vue';
import Utils from '@/main/resources/source/vue/composables/utils.js';
import ImageComponent from '@/main/resources/source/vue/components/ImageComponent.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const id = Utils.id(props.data.id);
const rowContainerId = `accordion-tab--rows__${id}`;
const {
  components, label, imageComponent, isOpen,
} = props.data;
const displayContent = ref(isOpen);
const chevronDownData = {
  size: 'default',
  image: 'chevron_down',
};
const chevronUpData = {
  size: 'default',
  image: 'chevron_up',
};

function toggleContent() {
  displayContent.value = !displayContent.value;
}
</script>

<template>
  <div
    :id="id"
    class="accordion-tab"
  >
    <div
      class="accordion-tab--label typography--body"
      @click.prevent="toggleContent"
    >
      <ImageComponent
        v-if="imageComponent"
        :data="imageComponent"
      />
      {{ label }}
      <div class="accordion-tab--open-status-icon">
        <ImageComponent
          v-if="displayContent"
          :data="chevronDownData"
        />
        <ImageComponent
          v-if="!displayContent"
          :data="chevronUpData"
        />
      </div>
    </div>
    <div
      :id="rowContainerId"
      class="accordion-tab--rows"
      :class="{ 'accordion-tab--rows__active': displayContent }"
    >
      <component
        :is="component.componentName"
        v-for="(component, index) in components"
        :key="index"
        :data="component"
      />
    </div>
  </div>
</template>

<script setup>
import Utils from '@/main/resources/source/vue/composables/utils.js';
import AccordionTabComponent from '@/main/resources/source/vue/components/accordion/AccordionTabComponent.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const id = Utils.id(props.data.id);
const { tabs } = props.data;
</script>

<template>
  <div
    :id="id"
    class="accordion"
  >
    <AccordionTabComponent
      v-for="(tab, index) in tabs"
      :key="index"
      :data="tab"
    />
  </div>
</template>

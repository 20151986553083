const FormUtils = {
  /**
   * Function that collects input values.
   * @returns {Object} Form data object with a {name: value} format.
   */
  collectFormData(data = {}) {
    const formInputs = document.querySelectorAll('#flowForm input');
    const formData = data;
    for (let i = 0; i < formInputs.length; i += 1) {
      const input = formInputs[i];
      if (input.type.toLowerCase() === 'checkbox') {
        formData[input.name] = input.checked;
      } else {
        formData[input.name] = input.value;
      }
    }
    // Also collect fake inputs
    const fakeInputs = document.querySelectorAll('#flowForm div.fake-input');
    for (let s = 0; s < fakeInputs.length; s += 1) {
      const fakeInput = fakeInputs[s];
      formData[fakeInput.id] = fakeInput.innerHTML;
    }
    return formData;
  },
  collectNounce() {
    const nounceId = 'nounce';
    const nounce = document.getElementById(nounceId);
    const data = {};
    if (nounce) {
      data[nounceId] = nounce.value;
    }
    return data;
  },
};

export default FormUtils;

<script setup>
import Utils from '@/main/resources/source/vue/composables/utils.js';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const id = Utils.id(props.data.id);
const classList = Array.isArray(props.data.classList) ? props.data.classList : [];
</script>

<template>
  <div
    :id="id"
    :class="classList"
  >
    <slot />
  </div>
</template>

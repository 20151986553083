import { createApp, reactive } from 'vue';
import App from './App.vue';
import ButtonComponent from '@/main/resources/source/vue/components/ButtonComponent.vue';
import CheckboxComponent from '@/main/resources/source/vue/components/CheckboxComponent.vue';
import ImageComponent from '@/main/resources/source/vue/components/ImageComponent.vue';
import InputComponent from '@/main/resources/source/vue/components/InputComponent.vue';
import LinkComponent from '@/main/resources/source/vue/components/LinkComponent.vue';
import NotificationComponent from '@/main/resources/source/vue/components/NotificationComponent.vue';
import PinInputComponent from '@/main/resources/source/vue/components/PinInputComponent.vue';
import SeparatorComponent from '@/main/resources/source/vue/components/SeparatorComponent.vue';
import SpaceComponent from '@/main/resources/source/vue/components/SpaceComponent.vue';
import TabComponent from '@/main/resources/source/vue/components/TabComponent.vue';
import TextComponent from '@/main/resources/source/vue/components/TextComponent.vue';
import WrapperComponent from '@/main/resources/source/vue/components/WrapperComponent.vue';
import AccordionComponent from '@/main/resources/source/vue/components/accordion/AccordionComponent.vue';
import AccordionTabComponent from '@/main/resources/source/vue/components/accordion/AccordionTabComponent.vue';
import AccordionDataRowComponent from '@/main/resources/source/vue/components/accordion/AccordionDataRowComponent.vue';

const config = reactive({
    state: {},
    followRedirects: true,
    localError: {
        show: false,
        data: {
            message: '',
        },
    },
    apiUrl: '/v2/api/',
    buttonTextCache: {},
    notifications: [],

    // Set up dynamically
    pageWasRenderedTimestamp: 0,
    currentInteractionStartTimestamp: 0,
    newStateHasArrivedTimestamp: 0,
});

const app = createApp(App);
app.provide('config', config);
app
  .component(ButtonComponent.__name, ButtonComponent)
  .component(CheckboxComponent.__name, CheckboxComponent)
  .component(ImageComponent.__name, ImageComponent)
  .component(InputComponent.__name, InputComponent)
  .component(LinkComponent.__name, LinkComponent)
  .component(NotificationComponent.__name, NotificationComponent)
  .component(PinInputComponent.__name, PinInputComponent)
  .component(SeparatorComponent.__name, SeparatorComponent)
  .component(SpaceComponent.__name, SpaceComponent)
  .component(TabComponent.__name, TabComponent)
  .component(TextComponent.__name, TextComponent)
  .component(WrapperComponent.__name, WrapperComponent)
  .component(AccordionComponent.__name, AccordionComponent)
  .component(AccordionTabComponent.__name, AccordionTabComponent)
  .component(AccordionDataRowComponent.__name, AccordionDataRowComponent);

document.addEventListener("DOMContentLoaded", async () => {
    app.mount('#frontend-app');
});

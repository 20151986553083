<script setup>
import Utils from '@/main/resources/source/vue/composables/utils.js';
import Images from '@/main/resources/source/vue/composables/images.js';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const id = Utils.id(props.data.id);
const {
  size, color, base64Image, image,
} = props.data;
const styleClasses = ['image', `image--${size}`];
const classList = Array.isArray(props.data.classList)
  ? props.data.classList.concat(styleClasses)
  : styleClasses;
const imageObject = new Images(image, color).get();
</script>
<template>
  <div
    v-if="base64Image"
    :id="id"
    :class="classList"
  >
    <img
      alt=""
      :src="base64Image"
    >
  </div>

  <div
    v-if="!base64Image"
    :id="id"
    :class="classList"
    v-html="imageObject"
  />
</template>

<script setup>
import Utils from '@/main/resources/source/vue/composables/utils.js';
import Images from '@/main/resources/source/vue/composables/images.js';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const id = Utils.id(props.data.id);
const isSmallClass = props.data.isSmall ? 'button--small' : '';
const viewClass = props.data.view && props.data.view.toString().toLowerCase() !== 'primary'
  ? `button--${props.data.view.toString().toLowerCase()}`
  : '';
const { type, label, disabled } = props.data;
const leftImage = new Images(props.data.leftImage).get();
const rightImage = new Images(props.data.rightImage).get();
</script>
<template>
  <button
    :id="id"
    class="button"
    :type="type"
    :name="id"
    :disabled="disabled"
    :class="[viewClass, isSmallClass, disabled]"
  >
    <div
      v-if="leftImage"
      :id="id + '-left-image'"
      v-html="leftImage"
    />{{ label }}<div
      v-if="rightImage"
      :id="id + '-right-image'"
      v-html="rightImage"
    />
  </button>
</template>

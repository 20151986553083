/**
 * Function (pseudo-class) for javascript hooks. Container for any interactive elements.
 *
 * @param data Hook data, should be served from backend.
 * @return {null} Returns null if data is not provided.
 * @constructor
 */

import HooksPool from '@/main/resources/source/vue/hooks/hooksPool.js';

class Hook {
  constructor(elementId, hooks) {
    if (!elementId || !hooks) {
      return;
    }
    this.id = elementId;
    this.hooksArray = hooks;
  }

  apply(config) {
    if (!this.id || this.hooksArray.length === 0) {
      return;
    }
    const { id } = this;
    this.hooksArray.forEach((hook) => {
      const { event } = hook;
      const { data } = hook;
      const hookToUse = HooksPool[hook.hookName];
      const elementPointer = document.getElementById(id);
      if (event === 'onload') {
        hookToUse({
          config,
          elementPointer,
          id,
          functionData: data,
        });
        return;
      }
      if (elementPointer === null) {
        return;
      }
      elementPointer.addEventListener(event, function onHookEvent(e) {
        e.preventDefault();
        hookToUse({
          id,
          config,
          functionData: data,
          elementPointer: this,
        }, e);
      });
    });
  }
}

export default Hook;

import Utils from '@/main/resources/source/vue/composables/utils.js';
import Hook from '@/main/resources/source/vue/hooks/hook.js';

const DomContentManipulator = {
  showModalOverlay(overlayId) {
    window.scrollTo(0, 0); // scroll to top to ensure elements is within view.
    const focusedInputItems = document.querySelectorAll('input');
    for (let i = 0; i < focusedInputItems.length; i += 1) {
      focusedInputItems[i].blur();
    }
    document.getElementById('modal-overlay').classList.add('modal-overlay--visible');
    document.getElementById(overlayId).style.display = 'block';
    document.documentElement.classList.add('scroll-y--hidden');
  },
  hideModalOverlay(overlayId) {
    document.getElementById('modal-overlay').classList.remove('modal-overlay--visible');
    document.documentElement.classList.remove('scroll-y--hidden');
    const specificOverlay = document.getElementById(overlayId);
    if (specificOverlay) {
      specificOverlay.style.display = 'none';
    }
  },
  displayTncContent(tncContainerId, content, scrollIntoView, config, overlayId) {
    const tncElement = document.getElementById(tncContainerId);
    if (!tncElement) {
      DomContentManipulator.displayLocalErrorNotification(config);
      return;
    }
    tncElement.innerHTML = content;
    tncElement.scrollIntoView();
    DomContentManipulator.showModalOverlay(overlayId);
    if (scrollIntoView) {
      const section = document.getElementById(scrollIntoView);
      if (section) {
        section.scrollIntoView();
      }
    }
  },
  displayLocalErrorNotification(config, message) {
    config.localError.data.message = message || document.getElementById('js-app-connectivityIssueErrorMessage').innerText;
    config.localError.show = true;
  },
  hideLocalErrorNotification(config) {
    config.localError.show = false;
  },
  processCenterContent(updatedState) {
    document.body.className = updatedState.showFooter ? '' : document.body.className;

    if (updatedState.centerContent === true) {
      document.body.classList.add('centered-body');
    } else {
      document.body.classList.remove('centered-body');
    }
  },
  processResponse(options = {}) {
    const { config, request } = options;

    let updatedState;
    try {
      updatedState = JSON.parse(request.response);
    } catch (e) {
      DomContentManipulator.displayLocalErrorNotification(config);
      return;
    }

    DomContentManipulator.hideLocalErrorNotification(config);
    DomContentManipulator.processCenterContent(updatedState);

    if (updatedState.components) {
      config.newStateHasArrivedTimestamp = Utils.getCurrentTimestamp();
      DomContentManipulator.hideModalOverlay();
      config.state.components = updatedState.components;
    }

    if (updatedState.replaceComponents) {
      updatedState.replaceComponents.forEach((replaceComponent) => {
        const index = config.state.components
          .findIndex((component) => component.id === replaceComponent.id);
        config.state.components[index] = replaceComponent;
      });
    }
    if (updatedState.errors) {
      for (let i = 0; i < updatedState.errors.length; i += 1) {
        const error = updatedState.errors[i];
        if (error.id) {
          DomContentManipulator.displayError(error.id, error.message, updatedState.errors);
        }
      }
      config.state.errors = updatedState.errors;
    }

    config.state.onload = updatedState.onload ? updatedState.onload : null;
  },
  applyHooks(config) {
    const { components } = config.state;
    if (!components) {
      return;
    }

    // Apply onload hooks
    const { onload } = config.state;
    if (onload && onload.elementHooksMap) {
      // Executing scripts that should be executed before as soon as page loads.
      const hooks = onload.elementHooksMap;
      // Execute onload scripts
      Object.keys(hooks)
        .filter((property) => Utils.hasOwnPropertyPrototypePointer(hooks, property))
        .forEach((hookKey) => {
          const hookToExecute = new Hook(hookKey, hooks[hookKey]);
          hookToExecute.apply(config);
        });
    }

    function applyHooksWithCheck(component) {
      const internalComponent = component;
      if (!internalComponent.hooks || internalComponent.hooksApplied) {
        return;
      }

      const keys = Object.keys(internalComponent.hooks.elementHooksMap);
      for (let i = 0; i < keys.length; i += 1) {
        const elementId = keys[i];
        const hook = new Hook(elementId, internalComponent.hooks.elementHooksMap[elementId]);
        hook.apply(config);

        internalComponent.hooksApplied = true;
      }
    }

    // Apply hooks for components
    for (let s = 0; s < components.length; s += 1) {
      applyHooksWithCheck(components[s]);
    }
  },
  displayError(id, errorMessage, allErrors) {
    const errorElement = document.getElementById(`${id}-error`);
    if (!errorElement) {
      return;
    }
    allErrors.shift();
    errorElement.classList.add('error-block');
    if (errorMessage) {
      errorElement.innerText = errorMessage;
    }
    errorElement.style.display = 'block';
    document.getElementById(id).classList.add('input--error');
  },
};

export default DomContentManipulator;

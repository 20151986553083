<script setup>
import Utils from '@/main/resources/source/vue/composables/utils.js';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const id = Utils.id(props.data.id);
const { value, validationMessage, adviceMessage } = props.data;
const classList = Array.isArray(props.data.classList) ? props.data.classList : [];
const checked = props.data.checked === true ? 'checked' : '';
</script>

<template>
  <div
    class="field checkbox--wrap"
    :class="classList"
  >
    <input
      :id="id"
      :name="id"
      :checked="checked"
      type="checkbox"
      value="true"
    >
    <label
      :for="id"
      v-html="value"
    />
    <div
      v-if="adviceMessage"
      class="typography--body_small_light advice-message"
      v-html="adviceMessage"
    />
    <div
      :id="id + '-error'"
      class="checkbox--error"
      v-html="validationMessage"
    />
  </div>
</template>

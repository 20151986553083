<script setup>
import Utils from '@/main/resources/source/vue/composables/utils.js';
import Images from '@/main/resources/source/vue/composables/images.js';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const id = Utils.id(props.data.id);
const label = props.data.label === undefined ? '' : props.data.label;
const type = props.data.type === undefined ? '' : props.data.type;
const autocomplete = props.data.autocomplete === undefined ? '' : props.data.autocomplete;
const value = props.data.value === undefined ? '' : props.data.value;
const placeholder = props.data.placeholder === undefined ? '' : props.data.placeholder;
const classList = Array.isArray(props.data.classList) ? props.data.classList : [];
const actionImage = new Images(props.data.actionImage).get();
const {
  isDisabled, topRightValue, bottomRightValue, bottomLeftValue,
} = props.data;
</script>

<template>
  <div
    class="field"
    :class="classList"
  >
    <label
      :for="id"
      class="field--hint"
      v-html="label"
    />
    <a
      v-if="topRightValue"
      :id="id + '-topRightAction'"
      class="field--hint alternative-action"
      v-html="topRightValue"
    />
    <div
      v-if="actionImage"
      :id="id + '-imageAction'"
      class="field--action-icon"
      v-html="actionImage"
    />
    <div
      v-if="isDisabled"
      :id="id"
      class="fake-input"
      v-html="value"
    />
    <input
      v-if="isDisabled"
      :id="id + '-disabled'"
      :type="type"
      :name="id + '-disabled'"
      :value="value"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      class="hidden"
    >
    <input
      v-if="!isDisabled"
      :id="id"
      :type="type"
      :name="id"
      :value="value"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
    >
    <div
      v-show="bottomLeftValue"
      :id="id + '-error'"
      class="field--hint field--bottom-hint"
      v-html="bottomLeftValue"
    />
    <div
      v-if="bottomRightValue"
      :id="id + '-bottomRightAction'"
      class="field--hint alternative-action field--bottom-hint"
      v-html="bottomRightValue"
    />
    <div class="clear" />
  </div>
</template>

<script setup>
import Utils from '@/main/resources/source/vue/composables/utils.js';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const id = Utils.id(props.data.id);
const {
  value, typography, isTitle, alignment, color,
} = props.data;
</script>

<template>
  <h1
    v-if="isTitle"
    :id="id"
    :class="['typography--' + typography, 'typography--' + alignment, 'typography--' + color]"
    v-html="value"
  />
  <div
    v-if="!isTitle"
    :id="id"
    :class="['typography--' + typography, 'typography--' + alignment, 'typography--' + color]"
    v-html="value"
  />
</template>

<script setup>
import { ref } from 'vue';
import Utils from '@/main/resources/source/vue/composables/utils.js';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});
const isHidden = ref(false);

const id = Utils.id(props.data.id);
const { message, isClosable } = props.data;
const view = props.data.view ? props.data.view.toString().toLowerCase() : 'error';
const isClosableClass = isClosable ? 'notification--closable' : '';
const typography = props.data.typography === undefined ? 'body' : props.data.typography;

function hide() {
  isHidden.value = true;
}
</script>

<template>
  <div
    v-if="!isHidden"
    :class="[
      'notification',
      'typography--' + typography,
      'notification--' + view,
      isClosableClass ]"
  >
    <div
      v-if="isClosable"
      :id="id"
      class="notification__close"
      @click="hide"
    />
    {{ message }}
  </div>
</template>

import Images from '@/main/resources/source/vue/composables/images.js';

const ElementBlocker = {
  freeze(params) {
    if (!params) {
      return;
    }
    const {
      elementPointer, time, blockedText, unblockedText, config,
    } = params;
    if (time === 0) {
      return;
    }
    ElementBlocker.block({ config, elementPointer, text: blockedText });
    setTimeout(() => {
      ElementBlocker.unblock({ config, elementPointer, text: unblockedText });
    }, time);
  },
  block(options = {}) {
    const { elementPointer, text, config } = options;

    elementPointer.setAttribute('blocked', 'true');
    elementPointer.classList.add('disabled');
    elementPointer.disabled = true;
    if (elementPointer.tagName.toLowerCase() === 'button') {
      const spinnerImage = new Images('loader');
      config.buttonTextCache[elementPointer.id] = elementPointer.innerHTML;
      elementPointer.innerHTML = spinnerImage.get();
    }
    if (text) {
      elementPointer.innerText = text;
    }
  },
  unblock(options = {}) {
    const { elementPointer, text, config } = options;
    elementPointer.removeAttribute('blocked');
    elementPointer.classList.remove('disabled');
    elementPointer.disabled = false;
    if (elementPointer.tagName.toLowerCase() === 'button') {
      elementPointer.innerHTML = config.buttonTextCache[elementPointer.id];
      config.buttonTextCache[elementPointer.id] = null;
    }
    if (text) {
      elementPointer.innerText = text;
    }
  },
  existsAndBlocked(elementPointer) {
    return elementPointer !== null && elementPointer.getAttribute('blocked') === 'true';
  },
};

export default ElementBlocker;

/**
 * Object that contains utility methods for framework.
 */
const Utils = {
  // TODO(sehii): if Asia will no longer be an issue - consider using this method instead
  //  https://stackoverflow.com/a/2117523
  /**
   * Function that creates a random UUID.
   * @returns {string} Random UUID.
   */
  id(id) {
    if (id) {
      return id;
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  /**
   * Function to clean up the url box in browser and remove get parameters.
   */
  removeGetParameters() {
    if (window.history && window.history.replaceState) {
      window.history.replaceState(
        {},
        document.title,
        window.location.origin + window.location.pathname,
      );
    }
  },
  hasOwnPropertyPrototypePointer(object, property) {
    const has = Object.prototype.hasOwnProperty;
    return has.call(object, property);
  },
  getCurrentTimestamp() {
    if (!Date) {
      return 0;
    }
    const date = new Date();
    return date.getTime();
  },
  getCurrentTimezoneOffset() {
    if (!Date) {
      return 0;
    }
    const date = new Date();
    return date.getTimezoneOffset();
  },
  getCurrentTimeZone() {
    if (typeof Intl !== 'undefined'
      && Intl.DateTimeFormat()
      && Intl.DateTimeFormat().resolvedOptions()) {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return '';
  },
  decodeBase64UrlToUint8ArrayBuffer(base64String) {
    const formattedBase64 = base64String.replace(/-/g, '+').replace(/_/g, '/');
    const byteCharacters = atob(formattedBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Uint8Array(byteNumbers);
  },
  encodeUin8ArrayBufferToBase64String(uin8ArrayBuffer) {
    const bytes = new Uint8Array(uin8ArrayBuffer);
    let str = '';
    bytes.forEach((charCode) => {
      str += String.fromCharCode(charCode);
    });
    return window.btoa(str)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  },
};

export default Utils;

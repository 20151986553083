<script setup>
import Utils from '@/main/resources/source/vue/composables/utils.js';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const id = Utils.id(props.data.id);
const { view } = props.data;
</script>

<template>
  <div
    :id="id"
    :class="'space_' + view"
  />
</template>

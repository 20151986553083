<script setup>
import Utils from '@/main/resources/source/vue/composables/utils.js';
import Images from '@/main/resources/source/vue/composables/images.js';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const id = Utils.id(props.data.id);
const {
  value, view, url, alignment, placement, typography,
} = props.data;
const type = view === 'block' ? 'link--block' : '';
const leftImage = new Images(props.data.leftImage).get();
const rightImage = new Images(props.data.rightImage).get();
const imageClass = leftImage || rightImage ? 'link--image' : '';
const styleClasses = [
  `float--${placement}`,
  `typography--${typography}`,
  `typography--${alignment}`,
  type,
  imageClass,
];
const classList = Array.isArray(props.data.classList)
  ? props.data.classList
    .concat(styleClasses)
  : styleClasses;
const { target } = props.data;
</script>

<template>
  <a
    :id="id"
    :href="url"
    :target="target"
    :class="classList"
    v-html="leftImage + value + rightImage"
  />
</template>

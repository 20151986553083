import Utils from '@/main/resources/source/vue/composables/utils.js';
import FormUtils from '@/main/resources/source/vue/composables/formUtils.js';
import Fetcher from '@/main/resources/source/vue/composables/fetcher.js';
import ElementBlocker from '@/main/resources/source/vue/composables/elementBlocker.js';
import DomContentManipulator from '@/main/resources/source/vue/composables/domContentManipulator.js';
import Images from '@/main/resources/source/vue/composables/images.js';
import picassoCanvas from '@/main/resources/source/vue/libs/canvas.js';
import md5 from '@/main/resources/source/vue/libs/md5.js';

/**
 * Object that stores hooks implementation.
 *
 * The names of functions inside should follow the ones available on the backend.
 */
const HooksPool = {
  RedirectHook(params) {
    const { functionData, config } = params;
    if (!config.followRedirects) {
      return;
    }
    window.location.replace(functionData.location);
  },
  SubmitFormHook(params, e) {
    const { elementPointer, functionData, config } = params;
    const endpoint = functionData.apiEndpoint;

    if (ElementBlocker.existsAndBlocked(elementPointer)) {
      return;
    }
    // Reset errors
    config.state.errors = {};
    DomContentManipulator.hideLocalErrorNotification(config);

    ElementBlocker.block({ elementPointer, config });

    const formData = FormUtils.collectFormData();
    if (functionData.otherAction) {
      formData[functionData.otherAction] = functionData.otherAction;
    }
    // Key stays for "Submit key"
    formData.pointerType = e.pointerType === '' ? 'key' : e.pointerType;
    formData.isTrusted = e.isTrusted;
    formData.elementId = elementPointer.id;
    if (e.currentTarget
      && typeof e.currentTarget.getBoundingClientRect === 'function'
      // Position of click makes no sense if submit key was pressed
      && e.pointerType !== '') {
      formData.relativeX = e.clientX - e.currentTarget.getBoundingClientRect().x;
      formData.relativeY = e.clientY - e.currentTarget.getBoundingClientRect().y;
      formData.elementHeight = e.currentTarget.getBoundingClientRect().bottom
        - e.currentTarget.getBoundingClientRect().y;
      formData.elementWidth = e.currentTarget.getBoundingClientRect().right
        - e.currentTarget.getBoundingClientRect().x;
    }
    Fetcher.request({
      url: config.apiUrl + endpoint,
      method: 'POST',
      body: formData,
    }, config, elementPointer);
  },
  NavigateBackHook(params) {
    const { elementPointer, config } = params;
    if (ElementBlocker.existsAndBlocked(elementPointer)) {
      return;
    }
    ElementBlocker.block({ config, elementPointer });
    Fetcher.request({
      url: `${config.apiUrl}back`,
      method: 'POST',
    }, config, elementPointer);
  },
  DisplayPrivacyHook(params) {
    const { elementPointer, functionData } = params;
    if (functionData.href) {
      elementPointer.href = functionData.href;
      if (functionData.target) {
        elementPointer.target = functionData.target;
      }
      return;
    }
    HooksPool.DisplayTncHook(params);
  },
  DisplayTncHook(params) {
    const { functionData, config } = params;

    DomContentManipulator.displayTncContent(
      functionData.tncContainerId,
      functionData.content,
      functionData.scrollIntoView,
      config,
      functionData.overlayId,
    );
  },
  ResendPinHook(params) {
    const { elementPointer, functionData, config } = params;
    // Non-trivial dance to remove all existing click listeners.
    const elementNode = elementPointer.cloneNode(true);
    elementPointer.parentNode.replaceChild(elementNode, elementPointer);

    ElementBlocker.freeze({
      config,
      elementPointer: elementNode,
      time: functionData.resendLockTime,
    });
    elementNode.addEventListener('click', (e) => {
      e.preventDefault();
      if (!ElementBlocker.existsAndBlocked(elementNode)) {
        Fetcher.request({
          url: config.apiUrl + functionData.apiEndpoint,
          method: 'POST',
          body: FormUtils.collectNounce(),
        }, config);
      }
    });
  },
  TogglePasswordFieldHook(params) {
    const { functionData } = params;
    const mainInput = document.getElementById(functionData.mainPasswordFieldId);
    const mainInputTopActionId = document.getElementById(functionData.topRightActionId);
    const mainInputActionImageId = document.getElementById(functionData.actionImageId);
    const secondaryInput = document.getElementById(functionData.secondaryPasswordFieldId);
    const passwordModeCheckboxId = document.getElementById(functionData.passwordModeCheckboxId);
    const { topRightActionShow } = functionData;
    const { topRightActionHide } = functionData;
    const image = new Images();
    // Not hidden
    if (secondaryInput.offsetParent !== null) {
      secondaryInput.parentElement.style.display = 'none';
      mainInput.type = 'text';
      mainInputTopActionId.innerHTML = topRightActionHide;
      mainInputActionImageId.innerHTML = image.get('eye');
      passwordModeCheckboxId.checked = false;
      return;
    }
    // hidden
    secondaryInput.parentElement.style.display = 'block';
    mainInput.type = 'password';
    mainInputTopActionId.innerHTML = topRightActionShow;
    mainInputActionImageId.innerHTML = image.get('eye_crossed');
    passwordModeCheckboxId.checked = true;
  },
  TogglePasswordFieldControlsHook(params) {
    const { functionData } = params;
    const mainInput = document.getElementById(functionData.mainPasswordFieldId);
    const mainInputTopActionId = document.getElementById(functionData.topRightActionId);
    const mainInputActionImageId = document.getElementById(functionData.actionImageId);
    if (mainInput.value.length === 0) {
      mainInputTopActionId.style.display = 'none';
      mainInputActionImageId.style.display = 'none';
    }

    mainInput.addEventListener('input', () => {
      if (mainInput.value.length === 0) {
        mainInputTopActionId.style.display = 'none';
        mainInputActionImageId.style.display = 'none';
        return;
      }
      mainInputTopActionId.style.display = 'block';
      mainInputActionImageId.style.display = 'block';
    });
  },
  PinListenerHook(params) {
    const { elementPointer, functionData } = params;
    if ('OTPCredential' in window
      && typeof navigator !== 'undefined'
      && navigator.credentials
      && navigator.credentials.get
      && typeof Promise !== 'undefined'
      && Promise.toString().indexOf('[native code]') !== -1) {
      // We are sure that promises are supported at this point
      const abortController = new AbortController();
      const inputField = elementPointer;
      // TODO(serhii): replace with id from config?
      document.getElementById('flowForm').addEventListener('submit', () => {
        abortController.abort();
      });
      navigator.credentials.get({
        otp: { transport: ['sms'] },
        signal: abortController.signal,
      }).then((otp) => {
        inputField.value = otp.code;
        const submitButton = document.getElementById(functionData.submitButtonId);
        if (submitButton) {
          submitButton.click();
        }
      }).catch(() => {
        // TODO(serhii): add proper error handling or reporting
        // Do nothing
      });
    }
  },
  ReloadPageHook() {
    window.location.reload();
  },
  ShowModalOverlayHook() {
    DomContentManipulator.showModalOverlay();
  },
  HideModalOverlayHook(params) {
    const { functionData } = params;
    DomContentManipulator.hideModalOverlay(functionData.overlayId);
  },
  HeImageLoadHook(params) {
    const { functionData, config } = params;
    const heImg = document.createElement('img');
    heImg.style.display = 'none';
    heImg.src = functionData.imageUrl;
    heImg.addEventListener('load', () => {
      const endpoint = functionData.successEndpoint;
      Fetcher.request({
        url: config.apiUrl + endpoint,
        method: 'POST',
        body: FormUtils.collectFormData({ heSuccess: true }),
      }, config);
    });
    heImg.addEventListener('error', () => {
      const endpoint = functionData.failureEndpoint;
      Fetcher.request({
        url: config.apiUrl + endpoint,
        method: 'POST',
        body: FormUtils.collectFormData({ heSuccess: false }),
      }, config);
    });
    document.getElementById('flowForm').appendChild(heImg);
  },
  PasskeyPrerollSignupHook(params) {
    const { functionData, config } = params;

    // Disable action buttons
    const nextButton = document.getElementById(functionData.submitButtonId);
    const nextButtonText = nextButton.innerText;
    const skipButton = document.getElementById(functionData.skipButtonId);
    const skipButtonText = skipButton.innerText;

    ElementBlocker.block({ config, elementPointer: nextButton });
    ElementBlocker.block({ config, elementPointer: skipButton });

    Fetcher.request({
      url: config.apiUrl + functionData.endpoint,
      method: 'POST',
      body: FormUtils.collectFormData(),
      failure() {
        ElementBlocker.unblock({ elementPointer: nextButton, text: nextButtonText, config });
        ElementBlocker.unblock({ elementPointer: skipButton, text: skipButtonText, config });
      },
    }, config);
  },
  PasskeySignupHook(params) {
    const { functionData, config } = params;

    // TODO(serhii): Check json validity?
    const credentialCreationOptions = JSON.parse(functionData.challenge);
    // Convert base64 binary data to actual binary data
    credentialCreationOptions.challenge = Utils
      .decodeBase64UrlToUint8ArrayBuffer(credentialCreationOptions.challenge);
    credentialCreationOptions.user.id = Utils
      .decodeBase64UrlToUint8ArrayBuffer(credentialCreationOptions.user.id);
    if (typeof credentialCreationOptions.excludeCredentials === 'object') {
      for (let i = 0; i < credentialCreationOptions.excludeCredentials.length; i += 1) {
        credentialCreationOptions.excludeCredentials[i] = {
          id: Utils.decodeBase64UrlToUint8ArrayBuffer(
            credentialCreationOptions.excludeCredentials[i].id,
          ),
          type: credentialCreationOptions.excludeCredentials[i].type,
        };
      }
    }
    // Disable action buttons
    const nextButton = document.getElementById(functionData.submitButtonId);
    const nextButtonText = nextButton.innerText;
    const skipButton = document.getElementById(functionData.skipButtonId);
    const skipButtonText = skipButton.innerText;

    ElementBlocker.block({ config, elementPointer: nextButton });
    ElementBlocker.block({ config, elementPointer: skipButton });

    const credentialsContainer = navigator.credentials; // CredentialsContainer
    const localCredentialOptions = {
      publicKey: credentialCreationOptions,
    };

    // Start the create process
    credentialsContainer.create(localCredentialOptions)
      .then((publicKeyCredential) => {
        // Transform publicKeyCredential to an object expected by backend
        const signupChallengeVerification = {};

        // Set regular values that doesn't require any transformation.
        // Note: we cannot simply copy `publicKeyCredential` object since it will cause issues with
        // overriding values from it.
        signupChallengeVerification.authenticatorAttachment = publicKeyCredential
          .authenticatorAttachment;
        signupChallengeVerification.id = publicKeyCredential.id;
        signupChallengeVerification.type = publicKeyCredential.type;
        if (typeof publicKeyCredential.response.getPublicKeyAlgorithm === 'function') {
          signupChallengeVerification.responsePublicKeyAlgorithm = publicKeyCredential
            .response.getPublicKeyAlgorithm();
        }
        if (typeof publicKeyCredential.response.getTransports === 'function') {
          signupChallengeVerification.responseTransports = publicKeyCredential
            .response.getTransports();
        }
        // Transform byte arrays to base64url
        signupChallengeVerification.rawId = Utils
          .encodeUin8ArrayBufferToBase64String(publicKeyCredential.rawId);
        signupChallengeVerification.responseAttestationObject = Utils
          .encodeUin8ArrayBufferToBase64String(publicKeyCredential.response.attestationObject);
        signupChallengeVerification.responseClientDataJson = Utils
          .encodeUin8ArrayBufferToBase64String(publicKeyCredential.response.clientDataJSON);
        if (typeof publicKeyCredential.response.getAuthenticatorData === 'function') {
          signupChallengeVerification.responseAuthenticatorData = Utils
            .encodeUin8ArrayBufferToBase64String(
              publicKeyCredential.response.getAuthenticatorData(),
            );
        }
        if (typeof publicKeyCredential.response.getPublicKey === 'function') {
          signupChallengeVerification.responsePublicKey = Utils
            .encodeUin8ArrayBufferToBase64String(
              publicKeyCredential.response.getPublicKey(),
            );
        }

        // After parsing done - send it out to backend
        Fetcher.request({
          url: config.apiUrl + functionData.endpoint,
          method: 'POST',
          body: FormUtils.collectFormData(signupChallengeVerification),
          failure(response) {
            DomContentManipulator.processResponse({ request: response, config });
            ElementBlocker.unblock({ elementPointer: nextButton, text: nextButtonText, config });
            ElementBlocker.unblock({ elementPointer: skipButton, text: skipButtonText, config });
          },
        }, config);
      })
      .catch((error) => {
        // Code 11 stands for InvalidStateError and that practically means that user already has
        // a key that he tries to sign up. This is an "error" case, however since the outcome is
        // exactly what user wants (his key being registered at our backend) we allow user to
        // proceed as if this was a success
        if (error.code === 11) {
          Fetcher.request({
            url: config.apiUrl + functionData.endpoint,
            method: 'POST',
            // Keep in mind that backend relies on keyAlreadyPresent key
            body: FormUtils.collectFormData({ keyAlreadyPresent: true }),
            failure(response) {
              DomContentManipulator.processResponse({ request: response, config });
              ElementBlocker.unblock({ elementPointer: nextButton, text: nextButtonText, config });
              ElementBlocker.unblock({ elementPointer: skipButton, text: skipButtonText, config });
            },
          }, config);
          return;
        }

        ElementBlocker.unblock({ elementPointer: nextButton, text: nextButtonText, config });
        ElementBlocker.unblock({ elementPointer: skipButton, text: skipButtonText, config });
        DomContentManipulator.displayLocalErrorNotification(
          config,
          functionData.genericErrorMessage,
        );
      });
  },
  PasskeyApiCheckHook(params) {
    const { elementPointer } = params;

    if (!window.PublicKeyCredential) {
      elementPointer.value = 'false';
      return;
    }

    window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
      .then((available) => {
        elementPointer.value = available;
      }).catch(() => {
        elementPointer.value = 'false';
      });
  },
  PasskeySigninHook(params) {
    const { functionData, config } = params;

    // TODO(serhii): Check json validity?
    const credentialCreationOptions = JSON.parse(functionData.challenge);
    credentialCreationOptions.challenge = Utils
      .decodeBase64UrlToUint8ArrayBuffer(credentialCreationOptions.challenge);
    if (typeof credentialCreationOptions.allowCredentials === 'object') {
      for (let i = 0; i < credentialCreationOptions.allowCredentials.length; i += 1) {
        credentialCreationOptions.allowCredentials[i] = {
          id: Utils.decodeBase64UrlToUint8ArrayBuffer(
            credentialCreationOptions.allowCredentials[i].id,
          ),
          type: credentialCreationOptions.allowCredentials[i].type,
          transports: credentialCreationOptions.allowCredentials[i].transports,
        };
      }
    }
    const credentialsContainer = navigator.credentials; // CredentialsContainer
    const localCredentialOptions = {
      publicKey: credentialCreationOptions,
    };

    // Disable action buttons
    const nextButton = document.getElementById(functionData.submitButtonId);
    const nextButtonText = nextButton.innerText;
    const skipButton = document.getElementById(functionData.skipButtonId);
    const skipButtonText = skipButton.innerText;

    ElementBlocker.block({ elementPointer: nextButton, config });
    ElementBlocker.block({ elementPointer: skipButton, config });
    // Start the create process
    credentialsContainer.get(localCredentialOptions)
      .then((publicKeyCredential) => {
        // Transform publicKeyCredential to an object expected by backend
        const signinChallengeVerification = {};

        if (!publicKeyCredential) {
          signinChallengeVerification.pkc = 'Was null';
        }

        // Set regular values that doesn't require any transformation.
        // Note: we cannot simply copy `publicKeyCredential` object since it will cause issues with
        // overriding values from it.
        signinChallengeVerification.authenticatorAttachment = publicKeyCredential
          .authenticatorAttachment;
        signinChallengeVerification.id = publicKeyCredential.id;
        signinChallengeVerification.type = publicKeyCredential.type;
        // Transform byte arrays to base64url
        signinChallengeVerification.rawId = Utils
          .encodeUin8ArrayBufferToBase64String(publicKeyCredential.rawId);
        signinChallengeVerification.responseAuthenticatorData = Utils
          .encodeUin8ArrayBufferToBase64String(publicKeyCredential.response.authenticatorData);
        signinChallengeVerification.responseClientDataJson = Utils
          .encodeUin8ArrayBufferToBase64String(publicKeyCredential.response.clientDataJSON);
        signinChallengeVerification.responseSignature = Utils
          .encodeUin8ArrayBufferToBase64String(publicKeyCredential.response.signature);
        signinChallengeVerification.responseUserHandle = Utils
          .encodeUin8ArrayBufferToBase64String(publicKeyCredential.response.userHandle);

        const endpoint = functionData.successEndpoint;

        Fetcher.request({
          url: config.apiUrl + endpoint,
          method: 'POST',
          body: FormUtils.collectFormData(signinChallengeVerification),
          failure(response) {
            DomContentManipulator.processResponse({ request: response, config });
            ElementBlocker.unblock({ elementPointer: nextButton, text: nextButtonText, config });
            ElementBlocker.unblock({ elementPointer: skipButton, text: skipButtonText, config });
          },
        }, config);
      })
      .catch((e) => {
        ElementBlocker.unblock({ elementPointer: nextButton, text: nextButtonText, config });
        ElementBlocker.unblock({ elementPointer: skipButton, text: skipButtonText, config });
        DomContentManipulator.displayLocalErrorNotification(
          config,
          functionData.genericErrorMessage,
        );
        console.log(e);
      });
  },
  ExternalCallbackHook(params) {
    const { functionData, config } = params;
    const endpoint = functionData.apiEndpoint;
    const formData = FormUtils.collectFormData();
    Fetcher.request({
      url: config.apiUrl + endpoint,
      method: 'POST',
      body: formData,
    }, config);
  },
  ShowTransferBetweenCreateAndLoginModalOverlay(params) {
    const { functionData } = params;
    document.getElementById(functionData.titleId).innerText = functionData.title;
    document.getElementById(functionData.descriptionId).innerText = functionData.description;
    document.getElementById(functionData.nextButtonId).innerText = functionData.nextButton;
    document.getElementById(functionData.tryAgainButtonId).innerText = functionData.tryAgainButton;
    DomContentManipulator.showModalOverlay(functionData.overlayId);
  },
  PicassoHook(params) {
    const { functionData } = params;

    // Setup canvas
    const picassoConfig = {
      area: {
        width: 300,
        height: 300,
      },
      offsetParameter: 2001000001,
      fontSizeFactor: 1.5,
      multiplier: 15000,
      maxShadowBlur: 50,
    };
    window.tidpHash = picassoCanvas(functionData.iterations, functionData.seed, picassoConfig);
    window.tidhHash = md5(functionData.seed + window.location.host);
    window.tidHost = window.location.host;
  },
};

export default HooksPool;

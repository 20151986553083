<script setup>
import Utils from '@/main/resources/source/vue/composables/utils.js';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const id = Utils.id(props.data.id);
const activeClass = props.data.isActive ? 'tab--active' : '';
const { value } = props.data;
const styleClasses = ['tab', activeClass];
const classList = Array.isArray(props.data.classList)
  ? props.data.classList.concat(styleClasses)
  : styleClasses;
</script>

<template>
  <div
    :id="id"
    :class="classList"
  >
    {{ value }}
  </div>
</template>

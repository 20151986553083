<script setup>
import '../styles/core.less';
import {
  inject, onMounted, onUpdated,
} from 'vue';
import Utils from '@/main/resources/source/vue/composables/utils.js';
import DomContentManipulator from '@/main/resources/source/vue/composables/domContentManipulator.js';
import NotificationComponent from '@/main/resources/source/vue/components/NotificationComponent.vue';
import ComponentsContainer from '@/main/resources/source/vue/partials/ComponentsContainer.vue';

const initialPayload = JSON.parse(document.getElementById('js-app-payload').innerText);
const brand = document.getElementById('js-app-brand').innerText;
const followRedirect = document.getElementById('js-app-followRedirects').innerText === 'true';

const config = inject('config');
config.state = initialPayload;
config.followRedirects = followRedirect;
config.pageWasRenderedTimestamp = new Date().getTime();

function preventDefault(event) {
  event.preventDefault();
}

onMounted(() => {
  if (config.state.cleanUrl) {
    Utils.removeGetParameters();
  }

  DomContentManipulator.processCenterContent(config.state);
  DomContentManipulator.applyHooks(config);
});
onUpdated(() => {
  DomContentManipulator.applyHooks(config);
});
</script>

<template>
  <form
    id="flowForm"
    method="post"
    class="form-wrap"
    data-formaction=""
    data-formbackaction=""
    novalidate
    @submit.prevent="preventDefault"
  >
    <div class="form-content-container">
      <div
        id="modal-overlay"
        class="modal-overlay"
      >
        <ComponentsContainer
          :container-id="'modal-overlay'"
          :components-to-render="config.state.components"
        />
      </div>
      <header
        id="header-with-logo"
        :class="'brand_' + brand"
      >
        <!-- One of the application containers -->
        <div
          id="header-container"
          class="container header-container"
        >
          <ComponentsContainer
            :container-id="'header-container'"
            :components-to-render="config.state.components"
          />
        </div>
        <!-- -->
      </header>
      <div
        id="content-container"
        class="container"
      >
        <div id="notifications">
          <NotificationComponent
            v-if="config.localError.show"
            :key="config.localError.data.message"
            :data="config.localError.data"
          />
          <ComponentsContainer
            :container-id="'notifications'"
            :components-to-render="config.state.components"
          />
          <ComponentsContainer
            :container-id="'notifications'"
            :components-to-render="config.state.errors"
          />
        </div>

        <div class="main-content-wrapper">
          <div
            id="app"
            class="app"
          >
            <ComponentsContainer
              :container-id="'app'"
              :components-to-render="config.state.components"
            />
          </div>
          <div class="clear" />
        </div>
      </div>
      <footer
        v-show="config.state.showFooter !== false"
        id="footer"
      >
        <div class="container container--footer">
          <div
            id="footer-links"
            class="footer-links"
          >
            <ComponentsContainer
              :container-id="'footer-links'"
              :components-to-render="config.state.components"
            />
          </div>
        </div>
      </footer>
    </div>
  </form>
</template>
